@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Golos Text' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Golos Text' !important;
  font-weight: 700 !important;
  letter-spacing: -3.5px;
  line-height: 100% !important;
}

.text-gradient-primary {
  background-image: linear-gradient(100deg, #2E56A7 0%, #BB6DE5 100%) !important;
}

.bg-gradient-primary {
  background-image: linear-gradient(180deg, #2E56A7 0%, #BB6DE5 100%) !important;
}

.btn-primary {
  background: linear-gradient(125deg, #2E56A7 0%, #BB6DE5 100%) !important;
  border: 0px !important;
}

.bg-primary {
  background: #BB6DE5 !important;
}

.text-primary {
  color: #BB6DE5 !important;
}

.text-third {
  color: #0e3f58 !important;
}

.bg-third {
  background-color: #0e3f58 !important;
}

.bg-gradient-faqs {
  background-image: linear-gradient(180deg, #DBE4FE 0%, #fff 20%);
}

.btn-xl {
  @apply !px-8 lg:!px-10 !py-3 lg:!py-4 !text-base
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.animate-1 {
  animation: float-1 5s ease-in-out infinite;
}
.animate-2 {
  animation: float-2 5s ease-in-out infinite;
}
.animate-3 {
  animation: float-3 5s ease-in-out infinite;
}
.animate-4 {
  animation: float-4 5s ease-in-out infinite;
}
.animate-5 {
  animation: float-5 3s ease-in-out infinite;
}
.animate-6 {
  animation: float-6 3s ease-in-out infinite;
}
.animate-7 {
  animation: float-7 2s ease-in-out infinite;
}

@keyframes float-1 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(12px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes float-2 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-14px, 2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes float-3 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(-18px, -9px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes float-4 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(20px, 8px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes float-5 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 20px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes float-6 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 8px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes float-7 {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 40px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}




.compare-section h1 {
  @apply text-[#001e13] text-4xl mt-12 mb-2 !font-[600] !leading-[120%];
  letter-spacing: normal !important;
}
.compare-section h3 {
  @apply text-[#001e13] text-2xl mt-6 -mb-2;
}
.compare-section p, .compare-section li, .compare-section p, .compare-section table {
  @apply text-lg !text-gray-700
}
.compare-section li {
  list-style-type: initial !important;
  @apply ml-10
}
.compare-section table {
  @apply mt-4;
}
.compare-section table th, .compare-section table td {
  vertical-align: top;
  @apply py-3 px-2 border
}
.compare-section table th {
  @apply text-left !font-black
}
.compare-section table td.font-bold {
  @apply !font-black;
}